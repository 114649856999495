import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import {
  useLegajoAlumnoDAO,
  usePersonaDAO,
  useResponsableDAO,
  useDatosLaboralesRespDAO,
  useDomicilioPersonaDAO,
  useMedioContactoDAO,
  useVinculoDAO,
  useDeudaAcademicaDAO,
  useProyeccionEstadoLibbyFetch,
  useInscripcionAlumnoDAO,
} from 'src/app/business';
import { PermissionBlocker } from 'src/lib/permission/components/PermissionBlocker';
import { BLOCKER_ID } from 'src/platform/permission/const/BlockerId';
import {
  level,
  Loading,
  loadingModal,
  TabBar,
  useBackButton,
  useRouteScreenTitle,
} from 'src/commons';
import { useRolesContext } from 'src/context/RolesContext';
import { FormContextProvider } from 'src/lib/templates';
import { FormFooter, Header } from './components';
import { LegajoDataProvider, LegajoDelAlumnoProvider } from './context';
import { useTemplateJoiner } from './hooks';
import { useLegajoLayout } from './index';
import {
  buildInitialLegajoAlumnoFormValues,
  buildLegajoAlumnoFormValues,
} from './lib';
import { tabs } from './tabs/tabs';
import { templates } from './templates';
import { useAlumnoFetch } from './hooks/useAlumnoFetch';
import { useLegajoAlumnoFetch } from './hooks/useLegajoAlumnoFetch';
import { useDatosInstitucionFetch } from './hooks/useDatosInstitucionFetch';
import { useGeneralContext } from 'src/context/GeneralContext';
import { isHistoricSection } from 'src/screens/Private/Matriculation/utils/isHistoricSection';
import {
  useAlumnoMovimientoDAO,
  useAlumnoMovimientoLibbyFetch,
} from 'src/app/business/AlumnoMovimiento';
import { useAlumnoMovimientoFetch } from './hooks/useAlumnoMovimientoFetch';
import { useInformeEpaDAO } from 'src/app/business/documents/InformesEpa';
import moment from 'moment';

const Screen: FunctionComponent = () => {
  const {
    dispatch,
    generalState: {
      cicloLectivo: currentCicloLectivo,
      legajo: { idDicloLectivo },
    },
  } = useGeneralContext()!;

  const informeEpaDAO = useInformeEpaDAO();
  const personaDAO = usePersonaDAO();
  const responsableDAO = useResponsableDAO();
  const datosLaboralesDAO = useDatosLaboralesRespDAO();
  const domicilioPersonaDAO = useDomicilioPersonaDAO();
  const medioContactoDAO = useMedioContactoDAO();
  const vinculoDAO = useVinculoDAO();
  const legajoAlumnoDAO = useLegajoAlumnoDAO();
  const deudaAcademicaDAO = useDeudaAcademicaDAO();
  const alumnoMovimientoDAO = useAlumnoMovimientoDAO();
  const inscripcionAlumnoDAO = useInscripcionAlumnoDAO();
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedRole: { nivel, modalidad, localizacionId },
  } = useRolesContext();

  const template = templates[modalidad];

  const {
    form: {
      setBeingSaved,
      setIsNewResponsable,
      setIsEditResponsable,
      isNewResponsable,
      isEditResponsable,
      setSaveDone,
    },
    data: {
      responsableData,
      responsableDatosLaborales,
      responsableDatosDomicilio,
    },
    working,
    params,
  } = useLegajoLayout();

  const {
    data: { alumno },
    recall: { recallAlumno },
  } = useAlumnoFetch();

  const {
    data: { legajoAlumno },
    recall: { recallLegajoAlumno },
  } = useLegajoAlumnoFetch();

  const filterProyeccionEstado = useMemo(
    () => ({
      0: [{ path: 'localizacion', value: localizacionId }],
    }),
    [localizacionId],
  );

  // Se consulta el estado para obtener el ciclo lectivo actual
  // siempre que éste no exista en el generalState
  const { data: proyeccionEstado = [] } = useProyeccionEstadoLibbyFetch({
    filter: filterProyeccionEstado,
    enabled: !Boolean(currentCicloLectivo),
    orderBy: 'cicloLectivo',
    direction: 'desc',
  });

  const alumnoFilter = useMemo(
    () => ({
      0: [
        {
          path: 'cicloLectivo.idCicloLectivo',
          value:
            idDicloLectivo || proyeccionEstado[0]?.cicloLectivo.idCicloLectivo,
        },
      ],
      1: [{ path: 'alumno.idAlumno', value: params[0] }],
    }),
    [idDicloLectivo, params, proyeccionEstado],
  );

  const {
    data: alumnoMovimiento,
    working: alumnoMovimientoWorking,
    reFetch,
  } = useAlumnoMovimientoLibbyFetch({
    filter: alumnoFilter,
    enabled: Boolean(idDicloLectivo),
  });

  const {
    data: { alumnoMovimiento: dataAlumnoMatriculado },
    working: dataAlumnoMatriculadoWorking,
  } = useAlumnoMovimientoFetch();

  const [isHistoric, setIsHistoric] = useState<boolean | undefined>();
  const [alumnoMatriculado, setAlumnoMatriculado] = useState(false);

  useEffect(() => {
    if (dataAlumnoMatriculado) {
      setAlumnoMatriculado(true);
    }
  }, [dataAlumnoMatriculado, dataAlumnoMatriculadoWorking]);

  const {
    data: { datosInstitucion },
    recall: { recallDatosInstitucion },
  } = useDatosInstitucionFetch();

  useEffect(() => {
    if (!alumnoMovimiento?.length && !datosInstitucion?.data) {
      setIsHistoric(true);
    }

    if (
      alumnoMovimiento &&
      alumnoMovimiento[0]?.cicloLectivo.anio &&
      (currentCicloLectivo || proyeccionEstado[0]?.cicloLectivo.anio)
    ) {
      setIsHistoric(
        isHistoricSection({
          currentCicloLectivo:
            currentCicloLectivo || proyeccionEstado[0].cicloLectivo.anio,
          cicloLectivo:
            alumnoMovimiento[0].cicloLectivo.anio ||
            datosInstitucion?.data?.cicloLectivo?.anio,
        }),
      );
    } else if (!alumnoMovimiento?.length) {
      setAlumnoMatriculado(false);
      if (datosInstitucion?.data) {
        setIsHistoric(false);
      }
    }
  }, [
    alumnoMatriculado,
    alumnoMovimiento,
    currentCicloLectivo,
    idDicloLectivo,
    isHistoric,
    proyeccionEstado,
    datosInstitucion,
  ]);

  const tabsAlumnosNoMatriculados = [
    'PersonalInformation',
    'HistorialAcademico',
    'Responsables',
  ];
  const filterTabs =
    isHistoric && !alumnoMatriculado
      ? tabs.filter(({ id }) => tabsAlumnosNoMatriculados.includes(String(id)))
      : tabs;

  // const {
  //   data: { matriculaPersonaExterna },
  // } = useMatriculaPersonaExternaFetch();
  // Se iguiala a un array vacío puesto que la funcionalidad no está implementada y por los momentos no es requerida
  const matriculaPersonaExterna: any[] = [];

  const templateMap = {
    datosPersonales: 'datosPersonalesTemp',
    documentacion: 'documentacionTemp',
    historialAcademico: 'historialAcademicoTemp',
    ppiPps: 'ppiPpsTemp',
    residencia: 'residenciaTemp',
    responsables: 'responsablesTemp',
    salud: 'saludTemp',
    situacionLegal: 'situacionLegalTemp',
  };

  // @ts-ignore
  const joinedTemplates = useTemplateJoiner(template, templateMap);

  useBackButton(
    '/private/matriculation',
    level.INICIAL === Number(nivel) ? 4 : 3,
  );
  useRouteScreenTitle('Estudiante');

  const handleSubmit = async (formValues: any) => {
    try {
      if (!alumno?.persona.idPersona || !legajoAlumno?.idLegajoAlumno) {
        enqueueSnackbar('No se encuentra el legajo del alumno.', {
          variant: 'warning',
        });
        return;
      }
      loadingModal.open();

      if (formValues.historialAcademico.datosToDelete !== undefined) {
        await Promise.all(
          formValues.historialAcademico.datosToDelete.map(
            (deudaAcademica: any) =>
              deudaAcademicaDAO.remove({
                idDeudaAcademica: deudaAcademica.idDeudaAcademica,
              }),
          ),
        );
      }
      formValues.historialAcademico.datosPrincipales.forEach((item: any) => {
        if (item.idDeudaAcademica === undefined) {
          deudaAcademicaDAO.save({
            fecha: item?.fecha,
            valoracion: item?.valoracion,
            calificacion: item?.calificacion?.name,
            apoyo: item?.apoyo?.value,
            alumno: item?.alumno,
            anio: item?.anio?.idAnio,
            cicloLectivo: item?.cicloLectivo?.idCicloLectivo,
            planEstudioMateria: item?.planEstudioMateria?.id,
            periodo: item?.periodo?.value,
            tipoDeudaAcademica: item?.tipoDeudaAcademica?.idTipoDeudaAcademica,
          });
        }
      });

      const {
        updatedPersona,
        updatedLegajoAlumno,
        updatedVinculo,
        updatedResponsable,
        updatedMedioContacto,
        updatedDomicilioPersona,
        updatedDatosLaborales,
        updatedPersonaResponsable,
      } = await buildLegajoAlumnoFormValues({
        alumno,
        datosInstitucion,
        formValues,
        legajoAlumno,
        responsableData,
        responsableDatosLaborales,
        responsableDatosDomicilio,
        modalidad,
      });
      if (isNewResponsable) {
        setBeingSaved?.(true);
        const data: any = await personaDAO.save(updatedPersonaResponsable);
        if (data?.idPersona) {
          await domicilioPersonaDAO.save({
            ...updatedDomicilioPersona,
            persona: { idPersona: data?.idPersona },
          });

          await medioContactoDAO.save({
            ...updatedMedioContacto.email,
            persona: { idPersona: data?.idPersona },
          });
          await medioContactoDAO.save({
            ...updatedMedioContacto.telefonoEmergencias,
            persona: { idPersona: data?.idPersona },
          });
          await medioContactoDAO.save({
            ...updatedMedioContacto.telefonoOtro,
            persona: { idPersona: data?.idPersona },
          });

          const dataResponsable = await responsableDAO.save({
            ...updatedResponsable,
            persona: { idPersona: data?.idPersona },
          });

          if (dataResponsable?.idResponsable) {
            const {
              tipoDomicilio,
              ocupacion,
              empresa,
              desdehastahoras,
              horasPorDia,
              cantDiasSemana,
              provincia,
              calle,
              altura,
              depto,
              codigoPostal,
              observaciones,
              piso,
            } = updatedDatosLaborales;
            const datosLaboralesEmpty =
              !tipoDomicilio ||
              !ocupacion ||
              !empresa ||
              !desdehastahoras ||
              !horasPorDia ||
              !cantDiasSemana ||
              !provincia ||
              !calle ||
              !altura ||
              !depto ||
              !codigoPostal ||
              !observaciones ||
              !piso;

            if (updatedDatosLaborales && !datosLaboralesEmpty) {
              await datosLaboralesDAO.save({
                ...updatedDatosLaborales,
                responsable: { idResponsable: dataResponsable?.idResponsable },
              });
            }

            await vinculoDAO.save({
              ...updatedVinculo,
              responsable: { idResponsable: dataResponsable?.idResponsable },
            });
          }
        }

        setIsNewResponsable?.(false);
      }
      if (isEditResponsable) {
        setBeingSaved?.(true);
        await personaDAO.save(updatedPersonaResponsable);
        await domicilioPersonaDAO.save(updatedDomicilioPersona);
        await responsableDAO.save(updatedResponsable);
        await vinculoDAO.save(updatedVinculo);
        await datosLaboralesDAO.save(updatedDatosLaborales);
        await medioContactoDAO.save(updatedMedioContacto.email);
        await medioContactoDAO.save(updatedMedioContacto.telefonoEmergencias);
        await medioContactoDAO.save(updatedMedioContacto.telefonoOtro);

        setIsEditResponsable?.(false);
      }
      // await inscripcionAlumnoDAO.save(updatedEnrolledAlumn); // Se comenta, a partir de ahora, la nueva lógica de inscripción y matriculación hace que esta llamada no sea necesaria
      await personaDAO.save(updatedPersona);
      if (updatedLegajoAlumno.fichaHistorialAcademico.informeEpa) {
        await informeEpaDAO.save({
          ...updatedLegajoAlumno.fichaHistorialAcademico.informeEpa,
          alumno: params[0],
        });
        delete updatedLegajoAlumno.fichaHistorialAcademico.informeEpa;
      }
      await legajoAlumnoDAO.save(updatedLegajoAlumno);

      const dateForm = moment(
        formValues?.datosPersonales?.datosInstitucion?.enrolledAt,
        'YYYY-MM-DD',
      );

      if (alumnoMovimiento && alumnoMovimiento?.length > 0 && dateForm) {
        const dateAlum = moment(alumnoMovimiento[0]?.enrolledAt, 'YYYY-MM-DD');
        if (!dateForm.isSame(dateAlum)) {
          await alumnoMovimientoDAO.save({
            ...alumnoMovimiento[0],
            enrolledAt: moment(dateForm, 'YYYY-MM-DD').toDate(),
          });
        }
      }
      /*     else if (datosInstitucion.data && dateForm) {
            const dateAlum = moment(datosInstitucion?.data?.enrolledAt, 'YYYY-MM-DD');
            if (!dateForm.isSame(dateAlum)) {
              await inscripcionAlumnoDAO.save({
                ...datosInstitucion.data,
                enrolledAt: moment(dateForm, 'YYYY-MM-DD').toDate(),
              });
            }
          } */
      setSaveDone?.(true);
      enqueueSnackbar('El legajo se ha guardado con éxito.', {
        variant: 'success',
      });
    } catch (error) {
      console.error('ERROR - LEGAJO ', error);
      enqueueSnackbar('No se pudo guardar el legajo. Volvé a intentarlo.', {
        variant: 'error',
      });
    } finally {
      loadingModal.close();
      // El formulario se reinicia, dejando los campos vacios.
      // Con esto, llenamos de nuevo los campos con los datos.
      await recallLegajoAlumno();
      await recallAlumno();
      await recallDatosInstitucion();
      reFetch();

      // Este dispatch sirve para actualizar los ids de las deudas
      // y así evitar deudas duplicadas
      dispatch.deudasAcademicasChanged(true);
    }
  };

  const initialValues = buildInitialLegajoAlumnoFormValues({
    alumno,
    legajoAlumno,
    alumnoMovimiento: alumnoMovimiento ? alumnoMovimiento[0] : undefined,
    matriculaPersonaExterna,
    datosInstitucion,
    responsableData,
    responsableDatosLaborales,
    responsableDatosDomicilio,
    modalidad,
  });

  return (
    <PermissionBlocker id={BLOCKER_ID.LEGAJO}>
      {!joinedTemplates ||
      working ||
      alumnoMovimientoWorking ||
      dataAlumnoMatriculadoWorking ? (
        <Loading />
      ) : (
        <LegajoDataProvider
          value={{
            working,
            alumno,
            legajoAlumno,
            alumnoMovimiento: dataAlumnoMatriculado,
            responsableData,
            responsableDatosLaborales,
            responsableDatosDomicilio,
            matriculaPersonaExterna,
            datosInstitucion,
            isHistoric,
          }}
        >
          <FormContextProvider
            template={joinedTemplates}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            <>
              <Header datosInstitucion={datosInstitucion} />
              <TabBar loading={working} content={filterTabs} />
              <PermissionBlocker id={BLOCKER_ID.EDIT_STUDENT}>
                <FormFooter isHistoric={isHistoric} />
              </PermissionBlocker>
            </>
          </FormContextProvider>
        </LegajoDataProvider>
      )}
    </PermissionBlocker>
  );
};

export const LegajoAlumnoScreen: FunctionComponent = () => (
  // @ts-ignore
  <LegajoDelAlumnoProvider>
    <Screen />
  </LegajoDelAlumnoProvider>
);
