import React, { useMemo } from 'react';
import moment from 'moment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { IconButton, Checkbox, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  Accion,
  GruposSelecciones,
  OfertaGrupo,
  TiposAcciones,
  Turno,
} from 'src/app/models';
import { Organizacion, Sector } from 'src/app/models/acap';
import { MUIEditorMIESC } from '../components/MUIEditorMIESC';
import { RenderPermissionBlocker } from 'src/lib/permission';
import { BLOCKER_ID } from '../../../../platform/permission/const';
import { ROL } from '../../../../commons';
import { useRolesContext } from '../../../../context/RolesContext';

const isString = (type: string) => type === 'string';

const useStyles = makeStyles({
  arrow: {
    color: '#00b3e3',
  },
  tooltip: {
    backgroundColor: '#00b3e3',
    fontSize: '13px',
  },
});

const someWithOfertaGrupo = (
  seleccionadas: GruposSelecciones[],
  grupo: OfertaGrupo,
) =>
  seleccionadas.some(
    (seleccion) => seleccion.ofertaGrupo.idOfertaGrupo === grupo.idOfertaGrupo,
  );

const rolCanBeSelectedOfert = [ROL.COORDINADOR_ORIENTACION, ROL.REFERENTE_ACAP];

export const useOfertaPreseleccionColumns = ({
  checkedOfertas,
  setCheckedOfertas,
  ofertasGruposFiltered,
  gruposSeleccionados,
  working,
  setTotalHorasACAP,
  handleOpenModal,
  orientacionesValidas,
  setSeeDetalle,
}: any) => {
  const { selectedRole } = useRolesContext();

  const isCheckedAll =
    checkedOfertas.length > 0
      ? checkedOfertas.length ===
        ofertasGruposFiltered.filter((grupo: any) => {
          const orientacionValidacion = orientacionesValidas?.filter(
            (idOrientacion: any) => {
              const hasSomeOrientacionInCommon =
                grupo?.ofertaTurno?.oferta?.accion?.accionOrientaciones?.some(
                  (accionOrientacion: any) => {
                    return (
                      Number(accionOrientacion?.orientacion?.idOrientacion) ===
                      Number(idOrientacion)
                    );
                  },
                );
              return hasSomeOrientacionInCommon;
            },
          );
          let shouldBeFiltered = !Boolean(orientacionValidacion.length);
          if (shouldBeFiltered) return false;

          return (
            gruposSeleccionados.findIndex(
              (seleccionado: GruposSelecciones) =>
                grupo?.idOfertaGrupo ===
                seleccionado?.ofertaGrupo?.idOfertaGrupo,
            ) === -1
          );
        }).length
      : false;
  const classes = useStyles();

  const columns = useMemo(
    () => [
      {
        id: 'icon',
        width: '1%',
        label: (
          <RenderPermissionBlocker
            id={BLOCKER_ID.CHECKBOX_OFERTA_ACTIVIDADES_APROXIMACION}
          >
            {(isBlocked) => {
              return (
                <Checkbox
                  checked={isCheckedAll}
                  disabled={
                    working ||
                    isBlocked ||
                    !ofertasGruposFiltered.filter(
                      (grupo: OfertaGrupo) =>
                        !someWithOfertaGrupo(gruposSeleccionados, grupo),
                    ).length ||
                    Number(selectedRole.rol) !== ROL.REFERENTE_ACAP
                  }
                  onChange={(e) => {
                    let totalHorasACAP = 0;
                    if (e.target.checked)
                      setCheckedOfertas(
                        ofertasGruposFiltered.filter((grupo: any) => {
                          const orientacionValidacion =
                            orientacionesValidas?.filter(
                              (idOrientacion: any) => {
                                const hasSomeOrientacionInCommon =
                                  grupo?.ofertaTurno?.oferta?.accion?.accionOrientaciones?.some(
                                    (accionOrientacion: any) => {
                                      return (
                                        Number(
                                          accionOrientacion?.orientacion
                                            ?.idOrientacion,
                                        ) === Number(idOrientacion)
                                      );
                                    },
                                  );
                                return hasSomeOrientacionInCommon;
                              },
                            );
                          let shouldBeFiltered = !Boolean(
                            orientacionValidacion.length,
                          );
                          if (shouldBeFiltered) return false;

                          if (!someWithOfertaGrupo(gruposSeleccionados, grupo))
                            totalHorasACAP +=
                              grupo?.ofertaTurno?.oferta?.accion?.horas;
                          return !someWithOfertaGrupo(
                            gruposSeleccionados,
                            grupo,
                          );
                        }),
                      );
                    else setCheckedOfertas([]);
                    setTotalHorasACAP(totalHorasACAP);
                  }}
                  inputProps={{
                    'aria-labelledby': 'transfer-list-item-all-label',
                  }}
                  color="primary"
                />
              );
            }}
          </RenderPermissionBlocker>
        ),
        disabled: true,
        hideSortIcon: true,
        noSort: true,
        align: 'left',
        render: (value: any, row: any) => {
          const orientacionValidacion = orientacionesValidas?.filter(
            (idOrientacion: any) => {
              const hasSomeOrientacionInCommon =
                row?.ofertaTurno?.oferta?.accion?.accionOrientaciones?.some(
                  (accionOrientacion: any) => {
                    return (
                      Number(accionOrientacion?.orientacion?.idOrientacion) ===
                      Number(idOrientacion)
                    );
                  },
                );
              return hasSomeOrientacionInCommon;
            },
          );

          let hasOrientaciones = orientacionValidacion.length > 0;
          let shouldBeDisabled: boolean =
            !rolCanBeSelectedOfert.includes(Number(selectedRole.rol)) ||
            !hasOrientaciones;
          const tooltipTitle =
            Number(selectedRole.rol) === ROL.COORDINADOR_ORIENTACION
              ? 'La oferta no coincide con la orientación asociada a su rol'
              : 'No tenés permiso para realizar esta acción';
          const disableTooltip = !shouldBeDisabled && hasOrientaciones;
          const isOfertaChecked =
            checkedOfertas.some(
              (ofertaGrupo: any) =>
                ofertaGrupo.idOfertaGrupo === row?.idOfertaGrupo,
            ) || someWithOfertaGrupo(gruposSeleccionados, row);
          return (
            <>
              <Tooltip
                title={tooltipTitle}
                disableFocusListener={disableTooltip}
                disableHoverListener={disableTooltip}
                disableTouchListener={disableTooltip}
              >
                <span>
                  <RenderPermissionBlocker
                    id={BLOCKER_ID.CHECKBOX_OFERTA_ACTIVIDADES_APROXIMACION}
                  >
                    {(isBlocked) => {
                      return (
                        <Checkbox
                          name={row.idOfertaGrupo}
                          checked={isOfertaChecked}
                          disabled={
                            isBlocked ||
                            someWithOfertaGrupo(gruposSeleccionados, row) ||
                            shouldBeDisabled
                          }
                          onChange={(e) => {
                            console.groupEnd();
                            if (isCheckedAll) {
                              setTotalHorasACAP(
                                row?.ofertaTurno?.oferta?.accion?.horas,
                              );
                              return setCheckedOfertas(
                                checkedOfertas.filter(
                                  (item: any) =>
                                    item.idOfertaGrupo !== row.idOfertaGrupo,
                                ),
                              );
                            }
                            if (isOfertaChecked) {
                              setCheckedOfertas((prev: any) => {
                                return prev.filter(
                                  (item: any) =>
                                    row?.idOfertaGrupo !== item.idOfertaGrupo,
                                );
                              });
                              setTotalHorasACAP(
                                (prev: any) =>
                                  prev -
                                  row?.ofertaTurno?.oferta?.accion?.horas,
                              );
                            } else
                              setCheckedOfertas((prev: any) => {
                                setTotalHorasACAP(
                                  (horas: any) =>
                                    horas +
                                    row?.ofertaTurno?.oferta?.accion?.horas,
                                );
                                return [...prev, row];
                              });
                          }}
                          inputProps={{
                            'aria-labelledby': 'transfer-list-item-all-label',
                          }}
                          color="primary"
                        />
                      );
                    }}
                  </RenderPermissionBlocker>
                </span>
              </Tooltip>
            </>
          );
        },
      },
      {
        id: 'organizacion',
        label: 'Organización',
        render: (value: Organizacion, row: OfertaGrupo) =>
          row?.ofertaTurno?.oferta?.accion?.organizacion?.nombre,
        orderById: 'ofertaTurno.oferta.accion.organizacion.nombre',
      },
      {
        id: 'sector',
        label: 'Sector',
        orderById: 'ofertaTurno.oferta.accion.organizacion.sector.nombre',
        render: (value: Sector, row: OfertaGrupo) =>
          row?.ofertaTurno?.oferta?.accion?.organizacion?.sector?.nombre,
      },
      {
        id: 'tipoAccion',
        label: 'Tipo de acción',
        orderById: 'ofertaTurno.oferta.accion.tipoAccion.nombre',
        render: (value: TiposAcciones, row: OfertaGrupo) =>
          row?.ofertaTurno?.oferta?.accion?.tipoAccion?.nombre,
      },
      {
        id: 'accion',
        label: 'Acción',
        orderById: 'ofertaTurno.oferta.accion.nombre',
        render: (value: Accion, row: OfertaGrupo) => {
          let editorText;
          try {
            editorText =
              JSON.parse(row?.ofertaTurno?.oferta?.accion?.descripcion)?.text ||
              JSON.parse(row?.ofertaTurno?.oferta?.accion?.descripcion);
          } catch (e) {
            editorText = row?.ofertaTurno?.oferta?.accion?.descripcion;
          }
          return (
            <Tooltip
              classes={classes}
              arrow
              title={<MUIEditorMIESC text={editorText} />}
            >
              <span>{row?.ofertaTurno?.oferta?.accion?.nombre}</span>
            </Tooltip>
          );
        },
      },
      {
        id: 'grupoId',
        label: 'Grupo',
        hideSortIcon: true,
        render: (_: unknown, row: OfertaGrupo) =>
          `${row?.ofertaTurno?.oferta?.accion?.nombre}, ${row?.ofertaTurno?.turno?.descripcionTurno}, id: ${row?.idOfertaGrupo}`,
      },
      {
        id: 'ofertaTurno.oferta.fechaInicio',
        label: 'Fecha de inicio',
        hideSortIcon: false,
        orderBy: 'ofertaTurno.oferta.fechaInicio',
        render: (value: any, row: any) =>
          row?.fechaInicio !== null
            ? moment(row?.fechaInicio).format('DD-MM-YYYY')
            : '',
      },
      {
        id: 'turno',
        label: 'Turno',
        orderById: 'ofertaTurno.turno.descripcionTurno',
        render: (value: Turno, row: OfertaGrupo) =>
          row?.ofertaTurno?.turno?.descripcionTurno,
      },
      {
        id: 'cupo',
        label: 'Cupo',
        hideSortIcon: true,
        render: (value: unknown, row: OfertaGrupo) =>
          row?.ofertaTurno?.cupo / row?.ofertaTurno?.cantGrupos,
      },
      {
        id: 'hsAcap',
        label: 'HS ACAP',
        hideSortIcon: true,
        render: (value: unknown, row: OfertaGrupo) =>
          row?.ofertaTurno?.oferta?.accion?.horas,
      },
      {
        id: 'jornadas',
        label: 'Jornadas',
        hideSortIcon: true,
        render: (value: unknown, row: OfertaGrupo) =>
          `${row?.jornadas?.length} días`,
      },
      {
        id: 'icon',
        label: 'Ver detalles',
        width: '10%',
        hideSortIcon: true,
        noSort: true,
        render: (value: unknown, row: OfertaGrupo) => {
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton onClick={() => handleOpenModal(row)}>
                <VisibilityIcon color="primary" />
              </IconButton>
            </div>
          );
        },
      },
    ],
    [
      isCheckedAll,
      working,
      ofertasGruposFiltered,
      gruposSeleccionados,
      setCheckedOfertas,
      setTotalHorasACAP,
      orientacionesValidas,
      checkedOfertas,
      selectedRole.rol,
      classes,
      handleOpenModal,
    ],
  );

  return columns;
};
